<template>
  <div class="sensitiveWords">
    <v-list
      ref="list"
      :isAdd="true"
      :isManySelect="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入" @click="toAdd"></v-button>
        <v-button text="导出" @click="toAdd"></v-button> -->
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="敏感词"
          clearable
          v-model="searchParam.word"
          @keyup.enter.native="$refs.list.search()"
        />
        <v-select
          clearable
          :options="wordsOptions"
          v-model="searchParam.reason"
          @change="$refs.list.search()"
          label="过滤原因"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="deleteWord(scope.row.id)" />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        ></v-button>
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  reasonsListUrl,
  sensitiveWordsListUrl,
  deleteWordsUrl,
  outputWordsUrl,
} from "./api.js";

export default {
  name: "sensitiveWords",
  data() {
    return {
      wordsOptions: [],
      searchParam: {
        word: null,
        reason: null,
      },
      headers: [
        {
          prop: "word",
          label: "敏感词",
        },
        {
          prop: "reason",
          label: "过滤原因",
        },
      ],
      extraParams: {},
      tableUrl: sensitiveWordsListUrl,
    };
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    batchSend(id) {
      console.log(id, "+++");
      let ids = id.ids.join(",");
      this.deleteWord(ids);
    },
    getReasons() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.$axios.get(`${reasonsListUrl}`, params).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.sensitiveWordReasonList) {
            res.data.sensitiveWordReasonList.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictKey;
              obj.value = ele.dictValue;
              this.wordsOptions.push(obj);
            });
          }
        }
      });
    },
    deleteWord(ids) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: ids,
        };
        this.$axios
          .post(deleteWordsUrl, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "addSensitiveWords",
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "accountAdd",
        query: { id: data.id, communityId: data.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sensitiveWords {
  box-sizing: border-box;
  height: 100%;
}
</style>
